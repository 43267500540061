.tab-bar {
  position: fixed;
  height: 53px;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px 15px calc(env(safe-area-inset-bottom) + 4px);
  border-top: 1px solid #eae5e1;
  background: #fff;
  z-index: 99;
  transition: transform 0.2s linear, -webkit-transform 0.2s linear;

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 11px;

    &-item {
      position: relative;
      width: 20%;
      text-align: center;
    }
  }

  &__link {
    height: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    width: 100%;
    white-space: nowrap;

    &--centered {
      position: absolute;
      padding: 0;
      height: auto;
      gap: 5px;
      left: 50%;
      top: -41px;
      transform: translate(-50%);
    }

    &-text {
      color: #98918a;
      text-align: center;
      font-size: 8px;
      text-transform: uppercase;

      &-active {
        color: #2f1f19;
      }
    }
  }

  &__icon {
    align-self: center;
  }
}

.is-hidden-bar {
  transform: translateY(145%);
}
