.item-location {
  margin: -10px;

  &__address {
    padding: 0 10px;
    font-family: 'Source Sans Pro', Arial, sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: #4a4a4a;
    line-height: 28px;
    background-color: #fff;
  }

  &__map {
    background-color: #f0f0f0;
    background-image: url('/static/images/map_stub.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
    width: 100%;
    height: 74px;

    &::after {
      content: '';
      display: block;
      width: 28px;
      height: 39px;
      position: absolute;
      background-image: url('/static/images/icon-pin.svg');
      background-size: contain;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      display: block;
      margin: 0 auto;
    }
  }
}
