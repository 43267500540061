.ap-gallery {
  height: 128px;
  background-color: #fff;
  overflow: hidden;

  &__thumbnail {
    height: 128px;
    width: 170px;

    &-img {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }
}
