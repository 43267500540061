.ap-filter {
  $this: &;
  background-color: #f4f4f4;

  &__title {
    padding: 5px 0 10px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: #4a4a4a;
    font-family: 'Source Sans Pro', Arial, sans-serif;
  }

  &__header {
    background-color: #f4f4f4;

    &--sticky {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 3;
      padding: 0 10px;
    }

    &-row {
      overflow-x: auto;
      display: flex;
      padding-bottom: 10px;
    }

    &-btn {
      flex: 1 0 auto;
      margin: 0;
      background-color: #fff;
      border: 1px solid #979797;
      font-size: 14px;
      font-weight: 600;
      color: #4a4a4a;
      padding: 11px 16px;
      display: inline-block;
      font-family: 'Source Sans Pro', Arial, sans-serif;
      text-decoration: none;

      &:first-of-type {
        border-radius: 4px 0 0 4px;
      }

      &:last-of-type {
        border-radius: 0 4px 4px 0;
      }

      & + & {
        border-left: 0;
      }

      &--active {
        background-color: #936447;
        color: #fff;
        border-color: #936447;
      }
    }
  }

  &__flat {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 1px 3px 0 #dbd8d8;
    padding: 5px 10px;

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1 0 auto;
      min-height: 86px;
      text-align: center;
      color: #000;
      font-weight: 600;
      font-family: 'Source Sans Pro', Arial, sans-serif;
      padding: 7px 10px 7px 0;
      box-sizing: border-box;
      border-right: 2px solid #f4f4f4;
    }

    &-area {
      margin: 0 auto 8px;
      font-size: 16px;

      &__value {
        color: #ce312d;
      }
    }

    &-price {
      &--label {
        display: block;
        font-size: 10px;
        font-weight: bold;
        color: #222;
        margin-left: -6px;
      }

      &__month {
        padding: 4px 12px;
        text-align: center;
        color: #fff;
        background-color: #936447;
        position: relative;
        font-size: 14px;
      }

      &__per-meter {
        color: #ce312d;
        font-size: 14px;
        text-align: center;
        padding: 5px 0;
      }

      &__mortgage {
        background-color: #efefef;
        position: relative;
        font-size: 13px;
        padding: 4px 12px;
        text-align: center;
        color: #936447;
        line-height: 17px;
      }
    }

    &-photos {
      display: flex;
      padding-left: 10px;
      align-items: center;
    }

    &-top {
      display: flex;
      border-bottom: 2px solid #f4f4f4;
      padding-bottom: 5px;
      overflow: hidden;
    }

    &-bottom {
      list-style: none;
      margin: 5px 0;
      padding: 0;
      width: 100%;
      color: #000;
      font-weight: 600;
      font-family: 'Source Sans Pro', Arial, sans-serif;
      font-size: 15px;

      p {
        padding: 0;
        margin: 0;
        text-align: center;
        white-space: pre-wrap;
      }
    }
  }
}
