.modal {
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100.1%; // fix bug with url bar in Chrome Mobile
    overflow-y: scroll;
    background-color: $colour-white;

    &--contacts-info {
      top: auto;
      overflow-y: hidden;
      border-radius: 7px 7px 0 0;
      height: auto;
    }

    &--map {
      position: relative;
      height: auto;
    }

    &--deteils-modal {
      background: #f6f4f1;
      overflow-y: auto;

      &-container {
        height: 600px;
      }
    }
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    font-family: $helvetica;
    background-color: $colour-white;
    // stylelint-disable-next-line
    -webkit-backface-visibility: hidden;

    &--map {
      background-color: rgba(0, 0, 0, 0.8);
    }

    &--contacts-info {
      background: rgba(0, 0, 0, 0.4);
    }

    &--deteils-modal {
      z-index: 96;
      padding-bottom: 130px;
    }
  }

  &__portal {
    -webkit-overflow-scrolling: touch;
  }

  &__body--open {
    overflow: hidden;
  }
}
