.development {
  &__i {
    padding-left: 22px;
    position: relative;
    font-size: 15px;
    line-height: 20px;
    color: #4a4a4a;

    &::before {
      content: '';
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      width: 18px;
      height: 18px;
      background-repeat: no-repeat;
      background-position: -54px -3px;
      background-image: url('/static/images/residence_sprite.svg');
    }

    &--developer {
      font-family: 'Source Sans Pro', Arial, sans-serif;
      font-weight: 600;

      &::before {
        background-position: -54px -3px;
      }
    }

    &--deadline {
      &::before {
        background-position: -32px 1px;
      }
    }
  }
}
