.bottom-phone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 48px;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  border-radius: 7px;
  background-color: #3a54f7;

  &-container {
    display: flex;
    align-items: center;
    height: 90px;
    margin: 0 5px;
  }

  &__wrapper {
    width: 100%;

    &--sticky {
      position: fixed;
      bottom: calc(env(safe-area-inset-bottom) + 10px);
      left: 15px;
      right: 15px;
      width: auto;
      z-index: 3;
    }
  }

  &--link {
    &::before {
      content: '';
      background-repeat: no-repeat;
      display: block;
      width: 15px;
      height: 15px;
      margin-right: 7px;
      background-image: url('/static/images/phone_call_white-icon.svg');
    }
  }
}
