.owner {
  &__name {
    color: #2f2119;
    font-size: 15px;
    font-weight: 500;
    font-family: $helvetica;
  }

  &__type {
    font-size: 14px;
    font-weight: 400;
    color: #606060;
    margin-top: 5px;
    font-family: $helvetica;
  }
}
