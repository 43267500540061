.breadcrumbs {
  width: 100%;
  overflow: hidden;

  &__white {
    background-color: #fff;
    padding: 3.125vw 0 10px;
  }

  &-list {
    padding: 15px 10px;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
    font-size: 0;
  }

  &-i {
    position: relative;
    display: inline-block;
    margin-right: 22px;
    color: #747474;
    font-size: 14px;

    a {
      color: #9b9b9b;
      text-decoration: none;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: -14px;
        width: 6px;
        height: 10px;
        margin-top: -4px;
        background-image: url('/static/images/breadcrumbs-icon.svg');
      }
    }

    p {
      color: #2e2e2e;
      margin: 0;
    }
  }
}
