.video-section {
  margin-bottom: 5px;
  box-shadow: 0 1px 3px 0 #dad7d7;
  border-radius: 2px;

  &__title {
    font-size: 16px;
    font-weight: 600;
    color: #4a4a4a;
    font-family: 'Source Sans Pro', Arial, sans-serif;
    padding: 6px 10px;
    background-color: #fff;
  }

  iframe {
    display: block;
    margin: auto;
  }
}
