.gallery {
  padding: 10px 0;
  background-color: #323232;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  &__row {
    display: flex;
    width: max-content;
  }

  &__thumbnail {
    position: relative;
    display: inline-block;
    width: 330px;
    height: 248px;
    margin-right: 10px;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;

    &:last-child {
      margin-right: 0;
    }

    &-img {
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }
}
