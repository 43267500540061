.lotriver-top-banner {
  margin-bottom: 10px;

  &--rows {
    margin-top: -8px;
  }

  &:empty {
    display: none;
  }

  a img {
    width: 100%;
    max-width: 480px !important; // sass-lint:disable-line no-important
    object-fit: cover;
  }
}

.lotriver-bottom-banner {
  width: 100%;
  margin-bottom: 10px;

  &.content-banner {
    overflow: hidden;

    @media screen and (min-width: 480px) {
      display: none;
    }
  }
}
