.contact-box {
  position: relative;
  height: 50px;
  padding-left: 45px;
  border-radius: 4px;
  border: solid 1px #e4e4e4;
  background-color: #f4f4f4;
  font-size: 16px;
  margin-top: 10px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #3c5efd;

  &::before {
    content: '';
    position: absolute;
    height: 18px;
    width: 18px;
    top: 50%;
    left: 15px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    transform: translateY(-50%);
  }

  &:first-of-type {
    margin-top: 5px;
  }

  &:last-child {
    margin-bottom: 5px;
  }

  &--phone {
    &::before {
      background-image: url('/static/images/phone_call_blue-icon.svg');
    }
  }

  &--email {
    &::before {
      background-image: url('/static/images/mail-icon.svg');
    }
  }

  &--website {
    &::before {
      background-image: url('/static/images/website-icon.svg');
    }

    &--facebook {
      &::before {
        background-image: url('/static/images/social/item-icons/facebook.svg');
      }
    }

    &--instagram {
      &::before {
        width: 24px;
        height: 24px;
        background-image: url('/static/images/social/item-icons/instagram.svg');
      }
    }

    &--youtube {
      &::before {
        width: 24px;
        height: 18px;
        background-image: url('/static/images/social/item-icons/youtube.svg');
      }
    }
  }

  &--schedule {
    &::before {
      background-image: url('/static/images/schedule-clock-icon.svg');
    }
  }

  &--brochure {
    &::before {
      background-image: url('/static/images/icon-download.svg');
    }
  }

  &__value {
    position: relative;
  }

  &__text {
    color: #3c5efd;
    margin-right: 15px;
    text-decoration: underline;
  }
}
