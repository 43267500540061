.contacts-info {
  &-container {
    position: relative;
    overflow: hidden;
    width: 100%;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    border-bottom: 0.5px solid #eae5e1;
    position: relative;

    &-title {
      color: #2f1f19;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0 auto;
      padding: 0 40px;
    }
  }

  &-content {
    padding: 0 15px;
    margin-bottom: 30px;
  }

  &__close-btn {
    position: absolute;
    right: 0;
    margin: 0 15px;
  }

  &-item-block {
    display: flex;
    width: 100%;
    height: 55px;
    align-items: center;
    border-bottom: 0.5px solid #eae5e1;
    text-decoration: none;

    &__icon {
      margin: 0 10px;
    }

    &__title {
      color: #2f1f19;
      font-size: 15px;
      font-weight: 400;
    }
  }
}
