.toggle-box {
  $this: &;
  padding: 0 0 32px;
  font-size: 15px;
  color: #000;
  position: relative;

  &__content {
    height: auto;
    max-height: 110px;
    overflow: hidden;
    transition: max-height 0.6s linear;

    #{$this}--residence & {
      max-height: 235px;
    }
  }

  &__input {
    display: none;

    + #{$this}__label {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 -10px;
      color: #072643;
      font-size: 15px;
      text-align: center;
      line-height: 32px;
      border-top: 3px solid #f4f4f4;
      background: #fff;
      cursor: pointer;

      &::before {
        content: '';
        display: block;
        height: 20px;
        width: 100%;
        position: absolute;
        bottom: 34px;
        box-shadow: inset 0 -8px 7px 6px rgba(255, 255, 255, 0.9);
      }
    }

    &:checked {
      ~ #{$this}__label {
        opacity: 0;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        #{$this}--unidirectional & {
          display: none;
        }
      }

      ~ #{$this}__content {
        max-height: 3000px;
        margin-bottom: -30px;
      }
    }
  }
}
