.white-header {
  &-container {
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    padding: 0 15px;
    border-bottom: 0.5px solid #eae5e1;

    &--fixed {
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 99;

      ~ .fallback-view,
      ~ .placeholder-st {
        margin-top: get-vw(55px);
      }
    }
  }

  &-btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &-title {
    color: #2f1f19;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }
}
