.bookmarking {
  &.card_view {
    background-image: url('/static/images/buttons-favorite.svg');
    background-position: 0 0;
    display: inline-block;
    border: 0;
    padding: 0;
    margin: 0;
    position: absolute;
    z-index: 4;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 17px;
    background-size: cover;
    background-repeat: no-repeat;

    .add_bookmark,
    .remove_bookmark {
      float: none;
      padding: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    &.added {
      background-position: 0 -17px;
    }
  }
}
