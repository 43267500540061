.m-nav {
  position: relative;
  height: 100%;
  min-height: get-vw(550px);
  box-sizing: border-box;

  &__link {
    display: block;
    width: 100%;
    padding: get-vw(10px) get-vw(15px);
    font-size: get-vw(14px);
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    text-align: left;
    background-color: transparent;

    &:active,
    &:focus {
      background-color: #424141;
    }
  }

  &__bookmarks {
    border-top: 1px solid #414141;

    .m-nav__link {
      position: relative;
      box-sizing: border-box;
      padding: 5vw 6.25vw 4.6875vw 12.1875vw;
      border-bottom: 1px solid #414141;

      &::before {
        background-image: url('/static/images/drawer/icon-bookmark.svg');
        content: '';
        width: get-vw(17px);
        height: get-vw(13px);
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 0;
        bottom: 0;
        left: get-vw(15px);
        margin: auto;
      }
    }
  }

  &__header {
    padding: 0;
    background-color: #4d4d4d;
    border-bottom: 1px solid #414141;
    position: relative;
    height: get-vw(68px);
    display: flex;
    align-items: center;

    &::before {
      background-image: url('/static/images/drawer/icon-user-grey.svg');
      background-repeat: no-repeat;
      background-size: cover;
      content: '';
      display: inline-block;
      width: get-vw(16px);
      height: get-vw(16px);
      position: absolute;
      left: get-vw(15px);
      top: 50%;
      margin-top: get-vw(-8px);
      flex: none;
    }

    &_auth {
      &-link {
        @include multiline-ellipsis($font-size: get-vw(14px));
        padding-left: get-vw(39px);
        padding-right: get-vw(10px);

        &--full-height {
          width: 100%;
          height: 100%;
          max-height: unset;
          display: flex;
          align-items: center;
        }
      }

      &-logout {
        padding: get-vw(10px);
        padding-left: get-vw(33px);
        margin-right: 20px;
        border-radius: get-vw(2px);
        background-color: #646464;
        position: relative;
        flex-shrink: 0;
        color: #fff;
        text-decoration: none;
        max-height: unset;

        &::before {
          background-image: url('/static/images/logout-grey.svg');
          background-repeat: no-repeat;
          background-size: cover;
          content: '';
          display: inline-block;
          width: get-vw(17px);
          height: get-vw(15px);
          position: absolute;
          left: get-vw(10px);
          top: 50%;
          margin-top: get-vw(-7.5px);
        }
      }
    }
  }

  &__footer {
    padding: get-vw(16px) 0;

    a {
      color: #fff;
      text-decoration: none;
    }

    &_support {
      margin-top: get-vw(60px);

      a {
        text-decoration: underline;
        padding: get-vw(7.5px) get-vw(15px);
      }

      &-title {
        padding-left: get-vw(15px);
        margin-bottom: get-vw(5px);
        color: #a2a0a0;
      }
    }
  }

  &__list {
    padding: get-vw(16px) 0;
    border-top: 1px solid #414141;
    border-bottom: 1px solid #414141;

    ul a {
      text-decoration: none;
    }
  }

  &__details {
    /* stylelint-disable */
    border-top: none;
    border-bottom: none;
    /* stylelint-enable */
    padding: 0;
    margin-top: 20px;

    &-link {
      background-color: #fff;
      border-top: 0.5px solid #eae5e1;
      border-bottom: 0.5px solid #eae5e1;
      height: 55px;
      padding: 19px 15px;
      color: #2f1f19;
      font-size: 15px;
      font-weight: 400;

      &-top {
        margin: 20px 0;
      }

      &-bottom {
        margin-top: 20px;

        &-transparent {
          background-color: transparent;
          /* stylelint-disable */
          border-bottom: none;
          /* stylelint-enable */
        }
      }
    }

    ul a {
      color: #2f1f19;
      font-size: 15px;
    }
  }
}

@media only screen and (min-width: 480px) {
  .m-nav {
    min-height: 550px;

    &__list {
      padding: 16px 0;
    }

    &__link,
    button {
      font-size: 14px;
      padding: 10px 15px;
    }

    &__header {
      $_this-company: &;
      height: 68px;

      &::before {
        width: 16px;
        height: 16px;
        left: 15px;
        margin-top: -8px;
      }

      &_auth {
        &::before {
          width: 16px;
          height: 16px;
          left: 15px;
          margin-top: -8px;
        }

        &-link {
          padding-left: 40px;
        }

        &-login {
          padding-left: 24px;
          padding-bottom: 9px;
        }

        &-logout {
          padding: 10px;
          padding-left: 33px;
          border-radius: 2px;
          font-size: 14px;
          margin: 0;
          margin-right: 15px;

          &::before {
            width: 18px;
            height: 15px;
            left: 10px;
            margin-top: -7.5px;
          }
        }

        &-name {
          @include multiline-ellipsis($font-size: 14px);
          padding-left: 24px;
          padding-right: 10px;
        }
      }
    }

    &__footer {
      padding: 16px 0;

      &_support {
        .drawer & {
          margin-top: 60px;
        }

        a {
          padding: 7.5px 15px;
        }

        &-title {
          padding-left: 15px;
          margin-bottom: 5px;
        }
      }
    }

    &__bookmarks {
      .m-nav__link {
        font-size: 14px;
        padding: 16px 20px 15px 39px;

        &::before {
          width: 17px;
          height: 13px;
          left: 15px;
        }
      }
    }
  }
}
